import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Grid, { GridSpacing } from '@material-ui/core/Grid'
import { IOverviewData } from '../pages/Dashboard'
import StatisticCard from './StatisticCard'
import DeviceCard from './DeviceCard'

interface SpacingGridProps {
  gridType: GridType
  data: IOverviewData | null
}

export enum GridType {
  Statistics,
  Devices,
}

export default function DashboardGrid(props: SpacingGridProps) {
  const [spacing, setSpacing] = React.useState<GridSpacing>(2)
  const classes = useStyles()

  let content

  if (props.gridType === GridType.Statistics) {
    if (props.data) {
      content = (
        <Grid container justify="center" spacing={spacing}>
          {props.data.statistics.map((value) => (
            <Grid key={value.property_name} item>
              <StatisticCard statistic={value} />
            </Grid>
          ))}
        </Grid>
      )
    } else {
      // Show a loading card if we do not have the data yet.
      content = (
        <Grid container justify="center" spacing={spacing}>
          {[0, 1, 2, 3, 4].map((value) => (
            <Grid key={value} item>
              <StatisticCard statistic={null} />
            </Grid>
          ))}
        </Grid>
      )
    }
  } else {
    if (props.data) {
      content = (
        <Grid container justify="center" spacing={spacing}>
          {props.data.devices.map((value) => (
            <Grid key={value.bpapi_gateway_id} item>
              <DeviceCard device={value} />
            </Grid>
          ))}
        </Grid>
      )
    }
  }

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        {content}
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
  }),
)