import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import * as Colors from '../colors'
import { Statistic } from '../pages/Dashboard'
import { CircularProgress } from '@material-ui/core'
import { formatPowerData } from '../utils'

interface SimpleCardProps {
  statistic: Statistic | null
}

export enum CardType {
  Exported,
  Imported,
  Produced,
  CountRegulated,
}

export default function StatisticCard(props: SimpleCardProps) {
  const classes = useStyles()

  function getColorClass(propertyName: string, forText: boolean) {
    switch (propertyName) {
      case 'ams_current_power_usage':
      case 'ams_cumulative_plus_calculated_energy_today':
      case 'ams_cumulative_imported_energy':
        return forText ? classes.importedText : classes.importedBackground
      case 'ams_eap':
      case 'ams_ceae':
      case 'ams_cumulative_exported_energy_today':
        return forText ? classes.exportedText : classes.exportedBackground
      case 'ams_count_regulated':
        return forText ? classes.countRegulatedText : classes.countRegulatedBackground
      default:
        return ''
    }
  }

  function getPropertyFriendlyName(propertyName: string) {
    switch (propertyName) {
      case 'ams_current_power_usage':
        return 'Imported power now'
      case 'ams_cumulative_plus_calculated_energy_today':
        return 'Imported energy today'
      case 'ams_eap':
        return 'Exported power now'
      case 'ams_ceae':
        return 'Total exported energy'
      case 'ams_cumulative_imported_energy':
        return 'Total imported energy'
      case 'ams_cumulative_exported_energy_today':
        return 'Exported energy today'
      case 'ams_count_regulated':
        return 'Regulated devices now'
      default:
        return ''
    }
  }

  function getSuffix(propertyName: string) {
    switch (propertyName) {
      case 'ams_current_power_usage':
      case 'ams_eap':
        return 'W'
      case 'ams_cumulative_plus_calculated_energy_today':
      case 'ams_cumulative_exported_energy_today':
      case 'ams_ceae':
      case 'ams_cumulative_imported_energy':
        return 'Wh'
      default:
        return ''
    }
  }

  let cardContent
  if (props.statistic) {
    const suffix = getSuffix(props.statistic.property_name)
    cardContent = (
      <CardContent className={classes.cardContent}>
        {/*
          <Typography className={classes.title} color="textSecondary" gutterBottom>
      {props.statistic.property_name}
    </Typography>
      */}
        <div className={classes.cardContentContent}>
          <Typography
            className={getColorClass(props.statistic ? props.statistic.property_name : '', true)}
            variant="h4">
            <b>{formatPowerData(props.statistic.sum, suffix)}</b>
          </Typography>
          <Typography variant="h6" component="p">
            <b>Max:</b> {formatPowerData(props.statistic.max, suffix)}
            <br />
            <b>Min:</b> {formatPowerData(props.statistic.min, suffix)}
            <br />
            <b>Avg:</b> {formatPowerData(props.statistic.average, suffix)}
          </Typography>
        </div>
        <div
          className={
            classes.cardContentTitle +
            ' ' +
            getColorClass(props.statistic ? props.statistic.property_name : '', false)
          }>
          <Typography className={classes.white} variant="h6" component="h2" align="center">
            <b>{getPropertyFriendlyName(props.statistic.property_name)}</b>
          </Typography>
        </div>

        {/**
     <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
     */}
      </CardContent>
    )
  } else {
    cardContent = (
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          Loading..
        </Typography>
        <CircularProgress className={classes.progress} color="secondary" />
      </CardContent>
    )
  }

  return <Card className={classes.root}>{cardContent}</Card>
}

const useStyles = makeStyles({
  root: {
    minWidth: 300,
  },
  cardContent: {
    padding: '0 !important',
  },
  cardContentTitle: {
    padding: 0,
  },
  cardContentContent: {
    padding: 10,
  },
  importedBackground: {
    backgroundColor: Colors.COMFORT,
  },
  exportedBackground: {
    backgroundColor: Colors.ECO,
  },
  producedBackground: {
    backgroundColor: Colors.ON,
  },
  countRegulatedBackground: {
    backgroundColor: Colors.REGULATED,
  },
  importedText: {
    color: Colors.COMFORT,
  },
  exportedText: {
    color: Colors.ECO,
  },
  producedText: {
    color: Colors.ON,
  },
  countRegulatedText: {
    color: Colors.REGULATED,
  },
  title: {
    fontSize: 14,
    color: 'white',
  },
  titleBlack: {
    fontSize: 14,
    color: 'black',
  },
  white: {
    color: 'white',
  },
  black: {
    color: 'black',
  },
  pos: {
    marginBottom: 12,
  },
  progress: {
    textAlign: 'center',
    margin: '20px',
  },
})
