import { User, Device } from '../App'

// Todo: Implement secret switch to change between prod and test
const apiUrl = 'https://api.connome.com/api/'

export interface HTTPRequest {
  method?: string
  query: string
  body?: string
  user: User
  callback: (data: any) => void
}

function fetchData(request: HTTPRequest) {
  const method = request.method
  const query = request.query
  const body = request.body
  // Todo: Credentials should never be stored in JS variables.
  // Implement JWT or similar serverside.
  const credentials = {
    email: request.user.email ? request.user.email : '',
    password: request.user.password ? request.user.password + '!!!' : '',
  }

  // We use base64 encoding (btoa) for the credentials
  const headers = new Headers({
    Authorization: 'Basic ' + btoa(credentials.email + ':' + credentials.password),
    'Content-Type': 'application/x-www-form-urlencoded',
  })

  // Setup init
  const requestInit = {
    headers: headers,
    method: method,
    body: method === 'POST' ? body : null,
  }

  // Todo: Fix naive approach
  if (!query.includes('Password')) {
    console.log('Request: ' + apiUrl + query)
  }

  // Perform request
  // Todo: Explain promise

  const fetchPromise = fetch(apiUrl + query, requestInit)
    .then((response) => {
      if (response.status !== 401) {
        return response.json()
      } else {
        console.error('Response status: ' + response.status)
        return request.callback(null)
      }
    })
    .then((data) => {
      return request.callback(data)
    })
    .catch((error) => {
      console.error('Something bad happened: ' + error)
      return request.callback(null)
    })

  return fetchPromise
}

export function verifyPassword(user: User): Promise<any> {
  const request = {
    method: 'POST',
    query: 'VerifyPassword',
    body: 'email=' + user.email + '&password=' + encodeURIComponent(user.password) + '!!!',
    user: user,
    callback: callback,
  }

  function callback(data: any) {
    const access_granted = data.Data.bpapi_result.access_granted
    if (access_granted === 'FullAccess') {
      return request.user
    }
    console.error('Error: ' + access_granted)
    return null
  }

  return fetchData(request)
}

export function getCustomerData(user: User, customerId?: number): any {
  const request = {
    method: 'GET',
    query: 'Customer/' + (customerId ? customerId : ''),
    user: user,
    callback: callback,
  }

  async function callback(data: any) {
    const customerData: any = data.Data.customer
    if (customerData === undefined) {
      return null
    }

    return customerData
  }

  return fetchData(request)
}

export function getCustomerProperty(user: User, customerId: number, propertyName: string) {
  return getProperty(user, 'Customer', customerId, propertyName)
}

export function getGatewayProperty(user: User, gatewayId: number, propertyName: string) {
  return getProperty(user, 'Gateway', gatewayId, propertyName)
}

function getProperty(user: User, parent: string, id: number, propertyName: string) {
  const request = {
    method: 'GET',
    query: parent + '/' + id + '/Property/' + propertyName,
    user: user,
    callback: callback,
  }

  function callback(data: any) {
    return data.Data.property.Value
  }

  return fetchData(request)
}

export function getProjectGatewayIds(user: User, projectId: number) {
  const request = {
    method: 'GET',
    query: 'Project/' + projectId + '/GatewayIds',
    user: user,
    callback: callback,
  }

  function callback(data: any) {
    if (data.Data.bpapi_result === undefined) {
      return []
    }
    return data.Data.bpapi_result.gateway_ids
  }

  return fetchData(request)
}

export function getGatewayPositions(user: User, id: number) {
  const request = {
    method: 'GET',
    query: 'Gateway/' + id + '/Property/GatewayIds',
    user: user,
    callback: callback,
  }

  function callback(data: any) {
    if (data.Data.bpapi_result === undefined) {
      return []
    }
    return data.Data.bpapi_result.gateway_ids
  }

  return fetchData(request)
}

export function getProjectOverview(user: User, projectId: number) {
  const request = {
    method: 'GET',
    query: 'Project/' + projectId + '/Overview',
    user: user,
    callback: callback,
  }

  function callback(data: any): IOverviewData {
    if (data.app === undefined) {
      console.error('Failed to get project overview')
    }

    return data.app
  }

  return fetchData(request)
}

interface IOverviewData {
  devices: Device[]
  statistics: any
}
