import React, { useState, useEffect } from 'react'
import { Button, Paper, makeStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import LanguageIcon from '@material-ui/icons/Language'
import MoonIcon from '@material-ui/icons/NightsStay'
import SunIcon from '@material-ui/icons/WbSunny'
import LogoBlack from './../assets/sikom-black.png'
import LogoWhite from './../assets/sikom-white.png'
import { Typography } from '@material-ui/core'
import { verifyPassword, getCustomerData } from '../networking/APIClient'
import { User } from '../App'
import { initProject } from '../utils'

interface ILoginProps {
  darkMode: boolean
  onThemeChangeClicked: Function
  handleLogin: Function
  openPage: Function
}

export default function Login(props: ILoginProps) {
  const classes = useStyles()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  // If email or password error !== null, we show the error in the string
  const [emailError, setEmailError] = useState<string | null>(null)
  const [passwordError, setPasswordError] = useState<string | null>(null)
  const [readyToLogin, setReadyToLogin] = useState(false)

  useEffect(() => {
    if (email !== '' && password !== '' && readyToLogin) {
      setReadyToLogin(false)
      tryLogin(email, password)
    }
  })

  // If the given customer is not a project, we must check if any other
  // customers have read rights towards this project, by checking the
  // ReadRights array.
  function tryFindValidProject(verifiedUser: User, customerData: any) {
    const readRightsArray: number[] = customerData.ReadRights

    // No read rights. Show error.
    if (readRightsArray.length === 0) {
      setPasswordError('')
      setEmailError('No access to power data. Contact support.')
    } else {
      // Loop through customer data for all customerIds we have been delegated rights to.
      // Select the first valid project we find.
      readRightsArray.map(async (customerId) => {
        const newCustomerData = await getCustomerData(verifiedUser, customerId)
        if (isValidProject(newCustomerData)) {
          props.handleLogin(verifiedUser, initProject(newCustomerData))
          return
        }
      })
    }
  }

  // If a project type is defined, and not None, we know that the customer is a project.
  function isValidProject(customer: any) {
    const projectType = customer.Properties.project_type
    if (projectType === undefined || projectType === 'None') {
      return false
    }
    return true
  }

  async function tryLogin(email: string, password: string) {
    if (email === '') {
      setEmailError('Please enter an email')
    }
    if (password === '') {
      setPasswordError('Please enter a password')
    }

    if (email && password) {
      try {
        const verifiedUser = await verifyPassword({ email: email, password: password })
        const customerData = await getCustomerData(verifiedUser)

        // If the customer is actually a project, we just log in.
        if (isValidProject(customerData)) {
          props.handleLogin(verifiedUser, initProject(customerData))
        } else {
          // If not, we must see if the customer has access to any projects
          // and use the (customer)data for that instead.
          tryFindValidProject(verifiedUser, customerData)
        }
      } catch (e) {
        setPasswordError('')
        setEmailError('Wrong email/password combination')
      }
    }
  }

  // We reset the error state when the user starts typing in one of the text fields.
  function resetErrorState() {
    setEmailError(null)
    setPasswordError(null)
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <img src={props.darkMode ? LogoWhite : LogoBlack} alt="Logo" className={classes.logo} />
        <Typography className={classes.titleColor} variant="h4">
          Power Manager
        </Typography>
        <form className={classes.textField}>
          <TextField
            id="standard-basic"
            label="Email"
            value={email}
            error={emailError !== null}
            helperText={emailError}
            onChange={(event) => {
              resetErrorState()
              setEmail(event.target.value)
            }}
            autoComplete = "on"
          />
          <TextField
            id="standard-password-input"
            label="Password"
            value={password}
            error={passwordError !== null}
            onChange={(event) => {
              resetErrorState()
              setPassword(event.target.value)
            }}
            type="password"
            autoComplete="current-password"
          />
          <Button
            className={classes.button}
            type="submit"
            color="primary"
            variant="contained"
            onClick={(event) => {
              event.preventDefault()
              setReadyToLogin(true)
            }}>
            Login
          </Button>
        </form>
        <div className={classes.margin}>
          {/* 
          Button is commented out until we decide to support more languages.
          <Button className={classes.marginRight} color="secondary">
            <LanguageIcon className={classes.bottomButtonIcon} />
            Norsk
          </Button>*/}
          <Button onClick={() => props.onThemeChangeClicked()} color="secondary">
            {props.darkMode ? (
              <SunIcon className={classes.bottomButtonIcon} />
            ) : (
              <MoonIcon className={classes.bottomButtonIcon} />
            )}
            {props.darkMode ? 'Light' : 'Dark'}
          </Button>
        </div>
      </Paper>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    minHeight: '100%',
    paddingTop: '15px',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '15px',
  },
  titleColor: {
    color: theme.palette.secondary.main,
  },
  paper: {
    textAlign: 'center',
    padding: '0px',
    width: '100%',
    minWidth: '300px',
    maxWidth: '400px',
  },
  textField: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '80%',
    },
    marginTop: '50px',
  },
  button: {
    width: '80%',
    marginTop: '30px',
    marginBottom: '30px',
  },
  marginLeft: {
    marginLeft: '5px',
  },
  marginRight: {
    marginRight: '5px',
  },
  margin: {
    margin: '30px',
  },
  small: {
    width: '40%',
  },
  fullWidth: {
    width: '100%',
  },
  bottomButtonIcon: {
    marginRight: '5px',
  },
  logo: {
    maxHeight: '50%',
    maxWidth: '50%',
    marginTop: '50px',
  },
}))
