import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import * as Colors from '../colors'
import { CircularProgress } from '@material-ui/core'
import { Device } from '../App'
import { formatPowerData } from '../utils'

interface Props {
  device: Device | null
}

export enum CardType {
  Exported,
  Imported,
  Produced,
  CountRegulated,
}

export default function DeviceCard(props: Props) {
  const classes = useStyles()

  // Todo: Find out how to align now and today on the same line? Use align?
  let cardContent
  if (props.device) {
    let amsContent = (
      <div>
        <Typography className={classes.importedColor} variant="h6" component="p">
          <b>Imported</b>
        </Typography>
        <div>
          <Typography variant="body1" component="p">
            <b>Now:</b> {formatPowerData(props.device.ams_current_power_usage, 'W')}
          </Typography>
          <Typography variant="body1" component="p">
            <b>Today:</b>{' '}
            {formatPowerData(props.device.ams_cumulative_plus_calculated_energy_today, 'Wh')}
          </Typography>
          <Typography variant="body1" component="p">
            <b>Total:</b> {formatPowerData(props.device.ams_cumulative_imported_energy, 'Wh')}
          </Typography>
        </div>
        <br />
        <Typography className={classes.exportedColor} variant="h6" component="p">
          <b>Exported</b>
        </Typography>
        <div>
          <Typography variant="body1" component="p">
            <b>Now:</b> {formatPowerData(props.device.ams_eap, 'W')}
          </Typography>
          <Typography variant="body1" component="p">
            <b>Today:</b> {formatPowerData(props.device.ams_cumulative_exported_energy_today, 'Wh')}
          </Typography>
          <Typography variant="body1" component="p">
            <b>Total:</b> {formatPowerData(props.device.ams_ceae, 'Wh')}
          </Typography>
        </div>
        <br />
        <Typography className={classes.regulatedColor} variant="body2" component="p">
          <b>Regulated devices:</b> {props.device.ams_count_regulated}
        </Typography>
      </div>
    )
    cardContent = (
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {props.device.ams_meter_id || 'No AMS meter connected'}
        </Typography>
        <Typography variant="h5" component="h2">
          {props.device.bpapi_gateway_id}
        </Typography>
        {props.device.ams_meter_id ? amsContent : null}
      </CardContent>
    )
  } else {
    cardContent = (
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          Loading..
        </Typography>
        <CircularProgress className={classes.progress} color="secondary" />
      </CardContent>
    )
  }
  return <Card className={classes.root}>{cardContent}</Card>
}

const useStyles = makeStyles({
  root: {
    minWidth: 250,
    minHeight: 375,
  },
  title: {
    fontSize: 14,
  },
  titleBlack: {
    fontSize: 14,
    color: 'black',
  },
  importedColor: {
    color: Colors.COMFORT,
  },
  exportedColor: {
    color: Colors.ECO,
  },
  regulatedColor: {
    color: Colors.REGULATED,
  },
  pos: {
    marginBottom: 12,
  },
  progress: {
    textAlign: 'center',
    margin: '20px',
  },
})