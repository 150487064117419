import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { User, Project, Device } from '../App'
import MyAppBar from '../components/MyAppBar'
import SpacingGrid, { GridType } from '../components/DashboardGrid'

interface IDashboardProps {
  user: User | null
  project: Project | null
  devices: Device[]
  handleLogout: any
  openPage: any
  setDevices: Function
  startPeriodicProjectOverviewFetching : Function
  overviewData: IOverviewData | null
}

export interface IOverviewData {
  devices: Device[]
  statistics: Statistic[]
}

export interface Statistic {
  property_name: string
  sum: number
  average: number
  min: number
  max: number
}

export default function Dashboard(props: IDashboardProps) {
  const classes = useStyles()

    // Version 1.0.0
    useEffect(() => console.log("v1.0.0"), []);

    // Delete local storage cache once the Dashboard is rendering.
    useEffect(() => {
      localStorage.removeItem("spmUser")
      localStorage.removeItem("spmProject")
    }, []);

    // Start periodic overview fetching for auto updating the data.
    useEffect(() => props.startPeriodicProjectOverviewFetching(), [])

  // Todo: Better solution than returning empty div?
  if (!props.user || !props.project) {
    console.log('Auto logout. No user.')
    props.handleLogout()
    return <div></div>
  }

  let content = (
    <div>
      <div className={classes.titleContainer}>
        <Typography className={classes.mainTitle} variant="h6" color="textPrimary">
          Overview
        </Typography>
      </div>
      <SpacingGrid data={props.overviewData} gridType={GridType.Statistics} />
      <br />
      <div className={classes.titleContainer}>
        <Typography className={classes.mainTitle} variant="h6" color="textPrimary">
          Devices
        </Typography>
      </div>
      <SpacingGrid data={props.overviewData} gridType={GridType.Devices} />
    </div>
  )

  return (
    <MyAppBar
      currentPage="Dashboard"
      handleLogout={props.handleLogout}
      openPage={props.openPage}
      content={content}
    />
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleContainer: {
      marginTop: '5px',
      marginBottom: '10px',
      backgroundColor: theme.palette.grey[600],
    },
    cardContent: {
      paddingBottom: '16px !important',
    },
    mainTitle: {
      color: 'white',
      paddingLeft: 25,
      paddingBottom: 3,
      paddingTop: 3,
    },
  }),
)
