import { Project } from './App'

export function formatPowerData(value: any, suffix: string) {
  // Count regulated (no suffix) should show the actual value, otherwise convert to kilo if over 1000
  if (value === '') return 'N/A'
  if (suffix === '') return value
  return value < 1000 ? value + ' ' + suffix : (value / 1000).toFixed(2) + ' k' + suffix
}

/**
 * Takes in @param customerData, and converts it and returns a Project.
 */
export function initProject(customerData: any): Project {
  const project: Project = {
    id: customerData.Properties.customer_id?.Value || '-1',
    projectType: customerData.Properties.project_type?.Value || '-1',
    bestEffortName: customerData.Properties.first_name?.Value || '',
    projectGatewayIds: [],
  }
  return project
}
