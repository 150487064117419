import React, { useEffect, useRef } from 'react';
import MyAppBar from '../components/MyAppBar'

const GOOGLE_MAP_API_KEY = 'AIzaSyD4Xpn-O2D1r5xtsAU0fZooqlQaGod9tvc';

// Pin styles
const pinSVGHole = "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z";
const pinSVGFilled = "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z";

const myLocation = {
  lat: 65.06035,
  lng: 12.17205
};

// Map style
const mapStyles = {
  padding: 0,
  width: '100%',
  height: '800px',
};

function Map(props) {
  const googleMapRef = React.createRef();
  const googleMap = useRef(null);
  const markers = useRef(null);

  const createGoogleMap = () =>
    new window.google.maps.Map(googleMapRef.current, {
      zoom: 5,
      center: {
        lat: myLocation.lat,
        lng: myLocation.lng
      },
      disableDefaultUI: true
    });

  function createMarkers(devices) {

    devices.map((device, key) => {
      // Pin styles
      const labelOriginHole = new window.google.maps.Point(12, 15);
      const labelOriginFilled = new window.google.maps.Point(12, 9);
      const comfortColor = "#d97f33";
      const offColor = "#919191";

      const lat = parseFloat(device.user_latitude);
      const lon = parseFloat(device.user_longitude);

      const pinColor = device.ams_meter_id ? comfortColor : offColor;
      const pinLabel = "" + device.bpapi_gateway_id;


      const markerImage = {  // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerLabel
        path: pinSVGHole,
        anchor: new window.google.maps.Point(12, 17),
        fillOpacity: 1,
        fillColor: pinColor,
        strokeWeight: 2,
        strokeColor: "black",
        scale: 1.5,
        labelOrigin: labelOriginHole
      };
      const label = {
        text: pinLabel,
        color: "white",
        fontSize: "16px",
      }; // https://developers.google.com/maps/documentation/javascript/reference/marker#Symbol

      // Todo: Label? Might be hard to see
      new window.google.maps.Marker({
        position: { lat: lat, lng: lon },
        map: googleMap.current,
        title: "" + device.bpapi_gateway_id + (device.ams_current_power_usage !== "NaN" ? ": " + device.ams_current_power_usage + " W" : ""),
        icon: markerImage,
        label: null
      });
    });
  }

  useEffect(() => {
    // Todo: Now, we add the map several times. We should only add once. See warning in console.
    const googleMapScript = document.createElement('script');
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`
    window.document.body.appendChild(googleMapScript);

    googleMapScript.addEventListener('load', () => {
      googleMap.current = createGoogleMap();
      markers.current = createMarkers(props.devices)
    })
  });

  const content =
    <div
      id="google-map"
      ref={googleMapRef}
      style={mapStyles}
    />

  return (
    <MyAppBar currentPage="Map" handleLogout={props.handleLogout} openPage={props.openPage} content={content} />
  );

}

export default Map