import React, { useState } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import { useMediaQuery, createMuiTheme, ThemeProvider, CssBaseline } from '@material-ui/core'
import Dashboard, { IOverviewData } from './pages/Dashboard'
import Login from './pages/Login'
import Map from './pages/Map'
import { getProjectOverview } from './networking/APIClient'

let projectOverviewFetcher: NodeJS.Timeout | null = null;

function App() {
  // This method is called when the user tries to refresh the page.
  // When a React app is refreshed, all the data will be deleted, and the app will no longer work.

  // To circumvent this katzenjammer, we store all relevant user data to local storage temporarily.
  // After the refresh, we then get all the items back from local storage and populate the app data with them.
  // This should result in the user staying on the same page.

  // Note that sensitive data should not be stored in local storage permanently, so we
  // delete the data in local storage as soon as the Dashboard page loads.
  window.onbeforeunload = () => {
    console.log("Reload")
    if (user === null || project === null) {
      // Only store values to local storage if they actually exist.
    } else {
      localStorage.setItem("spmUser", JSON.stringify(user))
      localStorage.setItem("spmProject", JSON.stringify(project))
    }
  };

  // For navigation
  const history = useHistory()

  // Theme related. We also store the user setting to local storage.
  // Media query to ask for the system color scheme. Currently unused.
  //let prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [darkMode, setDarkMode] = useState(shouldUseDarkMode());
  const palette = darkMode ? 'dark' : 'light';
  const currentTheme = palette === 'dark' ? darkTheme : lightTheme;
  // Called when we click the theme button
  const handleThemeChange = () => {
    setDarkMode(!darkMode);
    localStorage.setItem("slwaDarkModeEnabled", !darkMode ? "1" : "0")
  };

  const theme = createMuiTheme({
    palette: {
      type: palette,
      primary: currentTheme.primary,
      secondary: currentTheme.secondary,
    },
    overrides: {
      // Todo: Also set unselected from here?
      MuiListItem: {
        // For MenuItem, change this to MuiMenuItem
        root: {
          '&$selected': {
            // this is to refer to the prop provided by M-UI
            backgroundColor: currentTheme.secondary.main, // updated backgroundColor
            color: 'white',
          },
        },
      },
    },
  })

  const [user, setUser] = useState<User | null>(null)
  const [project, setProject] = useState<Project | null>(null)
  const [overviewData, setOverviewData] = useState<IOverviewData | null>(null)
  const [devices, setDevices] = useState<Device[]>([])

  function handleLogin(user: User, customer: Project) {
    if (customer.projectType === '-1') {
      console.error('No project type found')
      return
    }
    setUser(user)
    setProject(customer)
    history.push('/dashboard')
  }

    /**
   * We fetch updated overview data from the API every minute.
   */
     function startPeriodicProjectOverviewFetching() {
      fetchOverviewData()
      projectOverviewFetcher = setTimeout(startPeriodicProjectOverviewFetching, 60000)
    }
  
    /**
    * We stop fetching overview data when we log out.
    */
    function stopPeriodicProjectOverviewFetching() {
      if (projectOverviewFetcher) {
        clearTimeout(projectOverviewFetcher)
      }
    }
  
    function getObjectFromLocalStorage(key: string) {
      const object = localStorage.getItem(key)
      if (object !== null) {
        return JSON.parse(object)
      }
      return null
    }
  
    function shouldUseDarkMode() {
      const darkModeEnabled = localStorage.getItem("slwaDarkModeEnabled")
      if (darkModeEnabled === "1") {
        return true;
      }
      return false;
    }

    /**
   * Fetch overview data updates for all devices from the API.
   * This is done on an interval from the Dashboard page.
   */
       function fetchOverviewData() {
        if (user && project) {
          getProjectOverview(user, project.id).then((response) => {
            const overviewData = (response as unknown) as IOverviewData
            setOverviewData(overviewData)
            const devices = overviewData.devices
            setDevices(devices)
          })
        }
      }

  function handleLogout() {
    stopPeriodicProjectOverviewFetching()
    setUser(null)
    setProject(null)
    history.push('/')
  }

  function openPage(page: string) {
    history.push(page)
  }

  function getDashboardRoute() {
    let content;
    if (user !== null && project !== null) {
      content = <Route
      exact
      path="/dashboard"
      render={(props) => (
        <Dashboard
          {...props}
          user={user}
          project={project}
          devices={devices}
          handleLogout={handleLogout}
          openPage={openPage}
          setDevices={setDevices}
          startPeriodicProjectOverviewFetching={startPeriodicProjectOverviewFetching}
          overviewData={overviewData}
        />
      )}
    />
    } else {
      // If we are missing some objects, it is likely because the user has refreshed the page.
      // In this case, we try to get the data from local storage. If we cannot find it, we log out.
      const currentPathname = history.location.pathname
      if (currentPathname === "/") {
        // If we are at the login page, we do not need 
      } else {
        // Try to get the data objects from local storage
        const user = getObjectFromLocalStorage("spmUser")
        const customer = getObjectFromLocalStorage("spmProject")
        if (user !== null && customer !== null) {
          setUser(user)
          setProject(customer)
        } else {
          console.error("Missing data. Logging out.")
          handleLogout()
        }
      }
    }
    return content;
  }

  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline makes sure that the theme also changes to root color */}
      <CssBaseline />
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => (
            <Login
              {...props}
              darkMode={darkMode}
              onThemeChangeClicked={handleThemeChange}
              handleLogin={handleLogin}
              openPage={openPage}
            />
          )}
        />
        {getDashboardRoute()}
        <Route
          exact
          path="/map"
          render={(props) => (
            <Map openPage={openPage} handleLogout={handleLogout} devices={devices} />
          )}
        />
      </Switch>
    </ThemeProvider>
  )
}

export interface Project {
  id: number
  projectType: string
  bestEffortName: string
  projectGatewayIds: number[]
}

export interface User {
  email: string
  password: string
}

export interface Device {
  ams_meter_id: string
  bpapi_gateway_id: number
  user_latitude: string
  user_longitude: string
  ams_current_power_usage: number
  ams_cumulative_exported_energy_today: number
  ams_cumulative_plus_calculated_energy_today: number
  ams_eap: number
  ams_ceae: number
  ams_cumulative_imported_energy: number
  ams_count_regulated: number
}

export default App

const lightTheme = {
  type: 'light',
  primary: {
    light: '#e0985b',
    main: '#bd6b28',
    dark: '#925100',
    contrastText: '#fff',
  },
  secondary: {
    light: '#46abd6',
    main: '#2e92bd',
    dark: '#1c6280',
    contrastText: '#fff',
  },
}

const darkTheme = {
  type: 'dark',
  primary: {
    light: '#e0985b',
    main: '#bd6b28',
    dark: '#925100',
    contrastText: '#fff',
  },
  secondary: {
    light: '#46abd6',
    main: '#2e92bd',
    dark: '#1c6280',
    contrastText: '#fff',
  },
}
